@tailwind base;
@tailwind components;
@tailwind utilities;

.ease {
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
  }
  
  .easeFast {
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
  }

  @font-face {
    font-family: blocky;
    src: url("./assets/fonts/InriaSerif-Bold.ttf")
  }


  .fade-in {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }